import React, { useState, useEffect } from 'react'
import { graphql, StaticQuery } from 'gatsby'
import { Helmet } from "react-helmet"
import Layout from '../layouts/layout-screen'
import Wrapper from './wrapper'
import ContentScreen from '../components/content/content-screen'


const Index = () => {

    const [loaded, setLoaded] = useState( false );

    useEffect(()=>{
        setLoaded( true );
        console.log('ready');
    }, []);

    return (
        <StaticQuery
            query={graphql`
                query {
                    allWpPage(filter: {parent: {}, wpParent: {node: {slug: {eq: "home-page-2"}}}}, sort: {order: ASC, fields: menuOrder}) {
                      edges {
                        node {
                          title
                          uri
                          slug
                          excerpt
                          featuredImage {
                            node {
                              altText
                              sourceUrl
                              srcSet
                              mediaDetails {
                                sizes {
                                  width
                                  height
                                  sourceUrl
                                }
                              }
                            }
                          }
                          content
                        }
                      }
                    }
                    wpPage(isFrontPage: {eq: true}) {
                      title
                      uri
                      slug
                      excerpt
                      featuredImage {
                        node {
                          altText
                          sourceUrl
                        }
                      }
                      content
                      seo {
                        title
                      }
                    }
                  }
            `}
            render = {data => {

                const { allWpPage: pages , wpPage: page} = data;

                let siteTitle = page && page.seo.title ? page.seo.title : `${page.title} `;

                let rows = [];

                for (let row of pages.edges) {
                    let page = row.node;
                    let classes = rows.length && ! loaded ? 'd-none' : '';
                    rows.push(<ContentScreen classes={classes} key={row.node.slug} page={page}/>);
                }

                return (
                    <Layout type="boxed" type="home">
                        <Helmet encodeSpecialCharacters={false}>
                            <meta charSet="utf-8" />
                            <title>{siteTitle}</title>
                            {/* <link rel="canonical" href="http://mysite.com/example" /> */}
                        </Helmet>
                        <Wrapper>
                            {rows}
                        </Wrapper>
                    </Layout>
                )
            }}
        />
    );
}

export default Index

